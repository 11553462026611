.background-contact {
    width: 100vw;
    height: 90vh;
    background-image: url("/src/images/chriscontact.jpg");
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    background-size:cover;
    background-position: center;
    margin-top: 5rem;
    color: white;

}
.descr {
  font-size: 120%
}

.message-sent {
  font-size: 100%;
  color: green;
}

form {
  width: 40%;
  height: 40vh;
  width: 100%;
}

.message-input {
  height:70%;
  width: 100%;
  margin-top: 1rem;
  border-radius: 5px;
}

.customer-input {
  width: 100%;
  border-radius: 5px;
  outline: none;
}


.email-input {
  margin-top: 1rem;
  border-radius: 5px;
  width: 100%;
  outline: none;
}

.submit {
  border: none;
  border-radius: 5px;
  width: 20%;
  height: 12%;
  color: black;
  background-color: white;
}
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    text-align: center;
    height: 98%;
    border-radius: 16px;
    background-color: rgba(0,0,0,.5);
  }
  
  
  .inner {
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 250px;
    border-radius: 14px;
    bottom: 3rem;
  }
  .or {
    font-size: 120%;
  }
  .name {
    font-size: 250%;
    margin: 0;
    color: white;
  }

  /* Mobile Screen */
@media screen and (max-width: 600px) {
    .item {
        width: 350px;
        height: 90%;
      }
      
      .inner {
        width: 300px;
        height: 50%;
      }

      .inner h4 {
        font-size: 190%;
      }
      .inner p {
        font-size: 80%;
      }

      .inner .descr {
        margin-bottom: -1rem;
      }
      .inner .descr-n {
        margin-top: 1.5rem;
      }

      .or {
        margin-top: -0.7rem;
      }

      .submit {
        border: none;
        border-radius: 5px;
        width: 20%;
        height: 12%;
      }
}
