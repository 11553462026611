@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');

.Main {
    font-family: 'Instrument Serif', serif;

}

.main-title {
    text-align: center;
}

.main-title h1 {
    font-size: 3.5rem;
}

.main-title h2{
    font-size: 2rem;
    position: relative;
    bottom: 2rem;
}
.product-img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.main-title hr {
    width: 5rem;
    position: relative;
    bottom: 3rem;
}

.flower-name {
    border: 2px solid white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width: 98%;
    height: 10vh;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: green;
}

.center  {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(4, 1fr);
}

.wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-right: 2px;
	border-left: 0;
}


.wrapper img {
    width: 50%;
    height: 65%;
    align-items: center;
    align-content: center;
}

.wrapper h1 {
    text-align: center;
    font-size: 150%;
}

.wrapper hr {
    width: 3rem;
    position: relative;
    bottom: 0.8rem;
}
/* Normal Screen */
@media screen and (max-width: 1280px) {
    .center {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
/* Tablet Screen */
@media screen and (max-width: 950px) {
    .center {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
/* Mobile Screen */
@media screen and (max-width: 600px) {
    .center {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}