@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Varela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');

.header {
    width: 100vw;
    height: 550px;
    background-image: url("/src/images/chrisbackground.jpg");
    bottom:1rem;
    color: white;
    font-size: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-size:cover;
    background-position: center;


}
.title {
    color:forestgreen;
    margin-left: 1rem;
}
.about-content {
    color: white;
    margin-left: 1rem;
    font-family: 'Varela', sans-serif;
    font-size: 25px;
    width: 50rem;

}

button {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    background-color: green;
    outline: none;
    border: none;
    margin-left: 1rem;
    font-size: 1.5rem;
    font-family: 'Instrument Serif', serif;
}

button:hover {
    color: green;
    background-color: white;
}

@media screen and (max-width: 795px) {
    .about-content {
        width: 90vw;
        font-size: 3vw;
    }

    .title {
        font-size: 6vw; 
    }
    button {
        width: 25vw;
        height: 5vH;
        font-size: 3.5vw;
    }
}