.nav-bar {
    margin-bottom: 2rem;
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Varela&display=swap');

.nav-bar {
    font-family: 'Varela', sans-serif;
}
.nav-dimensions {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100vw;
    height: 8rem;
    /*box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);*/
}

.nav-items1:hover {
    color: red;
}

.nav-items2:hover {
    color: blue;
}

ul li {
    display: inline-block;
    font-size: 25px;
    margin-top: 2rem;
    padding-right: 2rem;
}

.logo {
    width: 180px;
    height: 100px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
/*****************************Responsive*********************************************/

a {
    color: #fff;
    text-decoration: none;
  }
  
  .p-menu1{
     height: 100%;
     display: flex;
     position: relative;
     justify-content: center;
     justify-items: center;
     align-items: center;
     flex-direction: column;
  }

  /* Hamburger */
  .hamburger1 {
    height: 20px;
    margin: 10px;
    display: -ms-grid;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    z-index: 120;
    position: absolute;
    left: 0rem;
  }

.hamburger1 div {
    background-color: rgb(61, 61, 61);
    position: relative;
    display: inline;
    width: 22px;
    height: 2px;
    margin-bottom: 1px;
    bottom: 6rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
  
  #toggle1 {
    cursor: pointer;
    bottom: 3.5rem;
    left: 0.2rem;
    width: 30px;
    height: 35px;
    display: none;
    position: absolute;
    
  }
  
  #toggle1:checked + .hamburger1 .top {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-top: 6px;
  }
  
  #toggle1:checked + .hamburger1 .meat {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin-top: -3px;
  }
  
  #toggle1:checked + .hamburger1 .bottom {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  
  #toggle1:checked ~ .menu1 {
    height: 340px;
  }
  
  
  /* Menu */
  .menu1 {
    width: 100%;
    margin: 0;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr repeat(4, 0.5fr);
    grid-row-gap: 25px;
    padding: 0;
    list-style: none;
    clear: both;
    width: auto;
    text-align: center;
    height: 0px;
    overflow: hidden;
    transition: height .4s ease;
    z-index: 120;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  hr {
    width: 50rem;
    height: 0px;
  }

  .menu1 a:hover {
    color: red;
  }

  .menu1 a {
    color: black;
    font-family: 'Varela', sans-serif;
    font-size: 1rem;

  }
  
  .menu1 a:first-child {
    margin-top: 15px;
  }
  
  .menu1 a:last-child {
    margin-bottom: 50px;
  }
  
  .link1 {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    font: 700 20px 'Varela', sans-serif;
  }
  
  .link1:hover {
    background-color: #fff;
    color: red;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }